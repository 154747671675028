import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/manage/activity/joinExamine/page`,
    method: 'get',
    params
  })
}

// export function delAPI(id) {
//   return http({
//     url: `/manage/activity/feedback/delete?id=${id}`,
//     method: 'post',
//     data: {}
//   })
// }
// export function addAPI(data) {
//   return http({
//     url: `/manage/activity/requirement/create`,
//     method: 'post',
//     data
//   })
// }

// export function editAPI(data) {
//   return http({
//     url: `/manage/activity/requirement/update`,
//     method: 'post',
//     data
//   })
// }

export function getDetailAPI(id) {
  return http({
    url: `/manage/activity/joinExamine/detail`,
    method: 'get',
    params: { id }
  })
}
