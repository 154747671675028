<template>
  <div class="detail-box">
    <div class="head-box">
      <el-image style="width: 60px; height: 60px; border-radius: 5px" :src="detail.userInfo.face" fit="contain"></el-image>
      <div class="detail">
        <div class="name">{{ detail.userInfo.realname }}</div>
        <div class="name">{{ detail.userInfo.mobile }}</div>
      </div>
    </div>
    <div class="content">
      <el-descriptions direction="vertical" :column="5" border>
        <el-descriptions-item label="活动名称">{{ detail.activityInfo.name }}</el-descriptions-item>
        <el-descriptions-item label="活动状态">
          <el-tag v-if="detail.status == 0" type="warning" size="mini">待审核</el-tag>
          <el-tag v-if="detail.status == 1" type="primary" size="mini">审核通过</el-tag>
          <el-tag v-if="detail.status == 2" type="danger" size="mini">审核拒绝</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="申请内容">
          {{ detail.content }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{ detail.createTime }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="tabs">
      <el-tabs v-model="activeName">
        <el-tab-pane label="用户信息" name="user">
          <el-descriptions :column="4">
            <el-descriptions-item label="昵称">{{ detail.userInfo.nickname }}</el-descriptions-item>
            <el-descriptions-item label="姓名">{{ detail.userInfo.realname }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ detail.userInfo.mobile }}</el-descriptions-item>
            <el-descriptions-item label="头像">
              <el-image style="width: 60px; height: 60px; border-radius: 5px" :src="detail.userInfo.face" fit="contain"></el-image>
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="活动详情" name="second">
          <el-descriptions :column="3">
            <el-descriptions-item label="活动名称">{{ detail.activityInfo.name }}</el-descriptions-item>
            <el-descriptions-item label="活动状态">
              <el-tag v-if="detail.status == 0" type="warning" size="mini">待审核</el-tag>
              <el-tag v-if="detail.status == 1" type="primary" size="mini">审核通过</el-tag>
              <el-tag v-if="detail.status == 2" type="danger" size="mini">审核拒绝</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="活动地址">{{ detail.activityInfo.address }}</el-descriptions-item>
            <el-descriptions-item label="开始时间">{{ detail.activityInfo.startTime }}</el-descriptions-item>
            <el-descriptions-item label="结束时间">{{ detail.activityInfo.endTime }}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeName: 'user'
    }
  },

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.detail-box {
  .head-box {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
    .detail {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
    }
  }
  .content {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  .tabs {
    padding: 0 20px;
  }
}
</style>
